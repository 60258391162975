<template>
  <div>
    <v-row dense>
      <v-col cols="4">
        <select-warehouse-model
          v-model="idWarehouse"
          :label="$t('labels.warehouse')"
          @change="onWarehouseChange"
        ></select-warehouse-model>
      </v-col>
      <v-col cols="8">
        <input-qr-scan-model
          ref="inputQr"
          v-model="code"
          :label="label"
          @keyupEnter="inputScan"
          :disabled="!idWarehouse"
        ></input-qr-scan-model>
      </v-col>
    </v-row>

    <div>
      <v-simple-table
        fixed-header
        height="calc(100vh - 145px)"
        class="table-padding-2-no-top"
      >
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.code") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.basket_code") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.sku") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.quantity") }}
            </th>
            <th style="width: 96px" role="columnheader" class="text-center">
              <v-btn
                x-small
                color="primary"
                @click="addItem"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="act in actions" :key="`act_${act.id}`" class="text-center">
            <td>
              {{ act.name }}
              <span v-if="act.code">({{ act.code }})</span>
            </td>
            <td>{{ act.basket_code }}</td>
            <td>{{ act.sku }}</td>
            <td>
              <span
                class="cursor-pointer primary--text text-decoration-underline"
                @click="showDetailUids(act)"
              >
                {{ act.sku ? act.uids.length : "" }}
              </span>
            </td>
            <td>
              <v-btn
                v-if="act.uids.length > 0"
                x-small
                color="success"
                block
                @click="showDialogConfirmFinish(act)"
              >
                {{ $t("labels.finish") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-dialog v-model="detailUidDialog" max-width="480px">
      <v-card v-if="detailUidDialog">
        <v-card-title class="text-h6">
          {{ detail.code }} - {{ detail.basket_code }}
          <v-spacer></v-spacer>
          <v-btn small color="red darken-1" text @click="hideDetailUids">
            {{ $t("labels.close") }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="4" v-for="sku_uid in detail.sku_uids" :key="sku_uid">{{
              sku_uid
            }}</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogActionFinish" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogActionFinish"
        :title="$t('labels.confirm_completion')"
        :description="$t('messages.are_you_sure_confirm_preparing')"
        :label="$t('labels.scan_basket_to_confirm')"
        :placeholder="$t('labels.scan_basket_to_confirm')"
        :codes="checkingCodes"
        @cancel="hideDialogConfirmFinish"
        @confirm="finishAction"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { generateRandomString } from "@/libs/helpers";

export default {
  name: "Prepair",
  components: {
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
  },
  data: () => ({
    idWarehouse: null,
    code: null,
    step: 1,
    isLoading: false,
    basket: {},
    action: {},
    goods: {},
    waitAdd: false,
    actions: [],
    detailUidDialog: false,
    detail: {},
    waitActionFinish: {},
    dialogActionFinish: false,
  }),
  watch: {},
  computed: {
    label() {
      if (this.waitAdd) {
        switch (this.step) {
          case 1:
            return this.$t("labels.code_action");
          case 2:
            return this.$t("labels.basket_code");
          case 3:
            return this.$t("labels.uid");
          case 4:
            return this.$t("labels.basket_code");
          default:
            return "--- ERROR ---";
        }
      } else {
        switch (this.step) {
          case 3:
            return this.$t("labels.uid");
          case 4:
            return this.$t("labels.basket_code");
          default:
            return "--- ERROR ---";
        }
      }
    },
    isDisabledBtnAdd() {
      return !!this.actions.find((act) => !act.code || !act.basket_code);
    },
    checkingCodes() {
      return [
        `${this.waitActionFinish.basket_code}`,
        this.waitActionFinish.basket_code,
      ];
    },
  },
  mounted() {
    this.getItemsPreparing();
  },
  methods: {
    generateRandomString,
    onWarehouseChange() {
      this.focusInputQr();
      this.resetValue();
    },
    focusInputQr() {
      setTimeout(() => {
        this.$refs.inputQr.focusInput();
      }, 200);
    },
    resetValue() {
      this.step = 1;
      this.actions = [];
      this.goods = {};
      this.addItem();
    },
    addItem() {
      const newAction = {
        id: this.generateRandomString(5),
        code: null,
        name: null,
        type: null,
        scan_basket: null,
        sku: null,
        uids: [],
        sku_uids: [],
        basket_code: null,
        id_warehouse: this.idWarehouse,
      };
      this.actions.push(newAction);
      this.waitAdd = true;
      this.step = 1;
      this.focusInputQr();
    },
    cancelAddItem() {
      this.waitAdd = false;
    },

    showDetailUids(item) {
      this.detail = { ...item };
      this.detailUidDialog = true;
    },

    hideDetailUids() {
      this.detail = {};
      this.detailUidDialog = false;
    },

    getItemsPreparing() {
      httpClient
        .post("/prepair-goods/v1/get-items-preparing")
        .then(({ data }) => {
          if (!data || data.length === 0) {
            this.resetValue();
          } else {
            this.actions = [...data].map((item) => ({
              id: this.generateRandomString(5),
              ...item,
            }));
            this.idWarehouse = data[0] && data[0].id_warehouse;
            this.step = 3;
          }
        });
    },

    async showDialogConfirmFinish(action) {
      this.waitActionFinish = { ...action };
      this.dialogActionFinish = true;
    },

    async hideDialogConfirmFinish() {
      this.waitActionFinish = {};
      this.dialogActionFinish = false;
    },

    async finishAction() {
      /* if (!confirm(this.$t('messages.are_you_sure_confirm_preparing'))) {
                return false
            } */
      const action = { ...this.waitActionFinish };
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/prepair-goods/v1/finish-action", {
          ...action,
          id_warehouse: this.idWarehouse,
        });

        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.hideDialogConfirmFinish();
        this.getItemsPreparing();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },

    inputScan() {
      if (!this.code) {
        return false;
      }

      if (this.waitAdd) {
        switch (this.step) {
          case 1:
            return this.scanAction();
          case 2:
            return this.scanBasket();
          case 3:
            return this.scanUid();
          case 4:
            return this.confirmItemAction();
          default:
            this.$vToastify.error("Wrong!");
        }
      } else {
        switch (this.step) {
          case 3:
            return this.scanUid();
          case 4:
            return this.confirmItemAction();
          default:
            this.$vToastify.error("Wrong!");
        }
      }
    },

    async scanAction() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const key = this.actions.length - 1;
      const actions = [...this.actions];

      try {
        const { data } = await httpClient.post("/prepair-goods/v1/get-code", {
          code: this.code,
          id_warehouse: this.idWarehouse,
        });
        actions[key].name = data.name;
        actions[key].code = data.code;
        this.actions = [...actions];

        this.step = 2;
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },

    async scanBasket() {
      const checkDuplicate = this.actions.find(
        (act) => act.basket_code == this.code
      );
      if (checkDuplicate) {
        this.$vToastify.error(
          this.$t("messages.busy_basket_location", {
            basket_location: this.code,
            item: checkDuplicate.code,
          })
        );
        this.code = null;
        document.getElementById("error_sound_player").play();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const key = this.actions.length - 1;
      const actions = [...this.actions];

      try {
        const { data } = await httpClient.post("/prepair-goods/v1/get-basket", {
          code: this.code,
          action_code: actions[key].code,
          id_warehouse: this.idWarehouse,
        });

        const basket = data.basket || {};

        actions[key].basket_code = basket.code;
        this.actions = [...actions];

        this.step = 3;
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },

    async scanUid() {
      const actions = [...this.actions];
      const key = actions.findIndex((act) => act.basket_code == this.code);
      if (actions[key]) {
        const action = actions[key];
        return this.showDialogConfirmFinish(action);
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/goods/v1/get-item", {
          code: this.code,
          id_warehouse: this.idWarehouse,
        });

        this.code = null;
        this.isLoading = false;

        if (!data.available) {
          this.$vToastify.error(this.$t("messages.uid_unavailable"));
          document.getElementById("error_sound_player").play();
          return false;
        }

        this.goods = { ...data };
        this.step = 4;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },

    async confirmItemAction() {
      const actions = [...this.actions];
      const key = actions.findIndex((act) => act.basket_code == this.code);
      const action = actions[key];

      if (
        !action ||
        this.code != action.basket_code ||
        (action.sku && action.sku != this.goods.sku)
      ) {
        this.code = null;
        this.$vToastify.error(this.$t("messages.wrong_basket_location"));
        document.getElementById("error_sound_player").play();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/prepair-goods/v1/set-item-to-action", {
          ...actions[key],
          sku_uid: this.goods.sku_uid,
          id_warehouse: this.idWarehouse,
        });

        this.code = null;
        this.isLoading = false;
        this.step = 3;

        actions[key].uids.push(this.goods.id);
        actions[key].sku_uids.push(this.goods.sku_uid);
        actions[key].sku = this.goods.sku;
        this.actions = [...actions];
        this.goods = {};

        document.getElementById("success_sound_player").play();
        this.cancelAddItem();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
